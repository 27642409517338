import { useSortable } from '@dnd-kit/sortable'
import {
  Button,
  DialogBody,
  DialogFooter,
  IconButton,
  IconButtonProps,
  Spinner,
  Tooltip,
} from '@material-tailwind/react'
import { Row } from '@tanstack/react-table'
import clsx from 'clsx'
import { MouseEvent, ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import useModal from '@/hooks/useModal.tsx'

export interface ActionProps<TData> {
  id: string
  icon: ReactNode
  title?: string | ((row: Row<TData>) => string)
  link?: string | ((row: Row<TData>) => string | LinkProps)
  onClick?: (data: Row<TData>, e: MouseEvent) => void
  loading?: boolean
  btnProps?: Omit<IconButtonProps, 'children' | 'ref'>
  sortable?: boolean
  modalTitle?: string
  modalContent?: ReactNode
  hidden?: (row: Row<TData>) => boolean
}
interface ActionsCellProps<TData> {
  row: Row<TData>
  actions: ActionProps<TData>[]
  className?: string
}

function Wrapper({ title, children }: { title?: string; children: ReactNode }) {
  return title ? (
    <Tooltip className="max-w-[300px]" content={title}>
      {children}
    </Tooltip>
  ) : (
    children
  )
}

export default function ActionsCell<TData>(props: ActionsCellProps<TData>) {
  const { listeners, attributes } = useSortable({
    id: props.row.id,
  })

  const [deleteModal, showDeleteModal] = useModal({
    noWrapBody: true,
  })

  return (
    <div
      data-testid="actions-cell"
      className={clsx(
        'flex-c-2 justify-end flex-wrap overflow-hidden',
        props.className,
      )}
    >
      {props.actions.map((act) => {
        const hidden = act.hidden?.(props.row)
        if (hidden) {
          return null
        }

        //if (act.sortable) {
        //  console.log('sortable btn', act.sortable, listeners, attributes)
        //}

        const btnProps = act.sortable
          ? {
              ...listeners,
              ...attributes,
            }
          : {}

        const link =
          typeof act.link === 'function' ? act.link(props.row) : act.link || ''

        const linkProps = typeof link === 'string' ? { to: link } : link

        const title =
          typeof act.title === 'function' ? act.title(props.row) : act.title

        return link ? (
          <Wrapper key={act.id} title={title}>
            <Link {...linkProps}>
              <div className="transition-colors w-8 h-8 rounded-md cc hover:bg-blue-gray-500">
                {act.icon}
              </div>
            </Link>
          </Wrapper>
        ) : (
          <Wrapper key={act.id} title={title}>
            <IconButton
              size="sm"
              variant="text"
              onClick={(e) => {
                if (act.onClick) {
                  if (act.id === 'delete') {
                    showDeleteModal(act.modalTitle || 'Archive', (onClose) => {
                      return (
                        <>
                          <DialogBody divider>
                            <div className="text-base">
                              {act.modalContent ||
                                'Are you sure you want to archive this item?'}
                            </div>
                          </DialogBody>
                          <DialogFooter className="flex-c-2">
                            <Button color="gray" size="sm" onClick={onClose}>
                              Cancel
                            </Button>
                            <Button
                              color="red"
                              size="sm"
                              onClick={(e) => act.onClick?.(props.row, e)}
                            >
                              Yes
                            </Button>
                          </DialogFooter>
                        </>
                      )
                    })
                  } else {
                    act.onClick(props.row, e)
                  }
                }
              }}
              {...act.btnProps}
              {...btnProps}
            >
              <div className="cc">{act.loading ? <Spinner /> : act.icon}</div>
            </IconButton>
          </Wrapper>
        )
      })}
      {deleteModal}
    </div>
  )
}
