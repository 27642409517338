import { lazy } from 'react'

import {
  AdminChildrenPath,
  AdminCrudPath,
  AdminLessonChildrenPath,
} from '@/constants/constants.ts'
import { PathKind } from '@/helpers/path.ts'
import daqRoute from '@/pages/AdminPage/AdminElementBuilderPage/AdminDAQBuilderPage/route.tsx'
import questionBuilderRoute from '@/pages/AdminPage/AdminElementBuilderPage/AdminQuestionBuilderPage/route.tsx'
import objectiveRoute from '@/pages/AdminPage/AdminSkillsPage/AdminSkillTagsPage/route.tsx'
import { sidebarHiddenPaths } from '@/services/app.ts'

// eslint-disable-next-line react-refresh/only-export-components
const AdminElementBuilderPage = lazy(() => {
  return import('@/pages/AdminPage/AdminElementBuilderPage/index.tsx')
})

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminLessonIndexPage.tsx'
  )
})

// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminLessonCreatePage.tsx'
  )
})

// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminLessonEditPage.tsx'
  )
})

// eslint-disable-next-line react-refresh/only-export-components
const Review = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminLessonReviewPage.tsx'
  )
})

// eslint-disable-next-line react-refresh/only-export-components
const Detail = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminLessonDetailPage/index.tsx'
  )
})

// eslint-disable-next-line react-refresh/only-export-components
/*const Import = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminLessonImportPage/index.tsx'
  )
})*/

// eslint-disable-next-line react-refresh/only-export-components
const Archive = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminLessonArchivePage/index.tsx'
  )
})

export default {
  path: AdminChildrenPath.LESSONS,
  element: <AdminElementBuilderPage />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:id/${AdminCrudPath.EDIT}`,
      element: <Edit />,
    },
    {
      path: `:id/${AdminCrudPath.REVIEW}`,
      element: <Review />,
    },
    {
      path: `:id/${AdminLessonChildrenPath.REVISION}/:revId`,
      element: <Detail />,
    },
    /*{
      path: `${AdminLessonChildrenPath.IMPORT}`,
      element: <Import />,
    },*/
    {
      path: `archives`,
      element: <Archive />,
    },
    questionBuilderRoute,
    objectiveRoute,
    daqRoute,
  ],
}

sidebarHiddenPaths.add(
  `/${PathKind.Admin}/${AdminChildrenPath.LESSONS}/:id/${AdminLessonChildrenPath.REVISION}/:revId`,
)
