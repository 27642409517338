import { Sym } from '@edclass/fe-ui'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import ActionsCell from '@/components/Cell/ActionsCell.tsx'
import {
  AdminChildrenPath,
  AdminCrudPath,
  BASE_PATH,
} from '@/constants/constants.ts'
import { mergePath, PathKind } from '@/helpers/path.ts'
import { DUMMY_CURRICULUMS } from '@/pages/AdminPage/AdminCurriculumsPage/dummy'
import GridProvider from '@/providers/GridProvider.tsx'

export default function AdminCurriculumsIndexPage() {
  const createColumn = createColumnHelper<Curriculum & { years: string[] }>()
  return (
    <GridProvider
      rows={DUMMY_CURRICULUMS}
      columns={
        [
          {
            accessorKey: 'id',
            header: 'ID',
          },
          {
            accessorKey: 'name',
            header: 'Name',
          },
          /*createColumn.accessor('years', {
            id: 'years',
            cell: (info) => (
              <ArrayCell
                getId={(v: string) => `${v}`}
                getVal={(v) => v.replace(/year/gi, '')}
                info={info}
              />
            ),
          })*/ createColumn.display({
            id: '#actions#',
            cell: (props) => (
              <ActionsCell
                row={props.row}
                actions={[
                  {
                    id: 'edit',
                    icon: <Sym className="!text-[20px]">edit_square</Sym>,
                    link: (row) => {
                      const value = row.getValue('id')
                      return mergePath(
                        BASE_PATH,
                        PathKind.Admin,
                        AdminChildrenPath.CURRICULUM,
                        AdminCrudPath.EDIT,
                        `${value}`,
                      )
                    },
                  },
                ]}
              />
            ),
          }),
        ] as ColumnDef<Curriculum & { years: string[] }>[]
      }
    ></GridProvider>
  )
}
