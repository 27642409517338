import { ApiEndpoint, FsEndpoint } from '@edclass/fe-common'
import { AxiosRequestConfig } from 'axios'

import { Http } from '@/helpers/http.ts'
import { BaseService } from '@/services/base.ts'
import { Params } from '@/services/params.ts'

const BASE_URL: string = import.meta.env.VITE_FS_API_URL
const SCHOOL_BUCKET_NAME: string = import.meta.env.VITE_SCHOOL_BUCKET_NAME
const APP_BUCKET_NAME: string = import.meta.env.VITE_APP_BUCKET_NAME

interface FsQueryOption {
  //isSchool?: boolean
  folder?: string
  //uploadedBy?: MoreSimpleUser & WithId
  tags?: FsTagReqBody
}

const DEFAULT_FS_QUERY_OPTION = {}

class FsService extends BaseService {
  protected _client: Http
  private static instance: FsService

  static getInstance(): FsService {
    if (!FsService.instance) {
      FsService.instance = new FsService()
    }
    return FsService.instance
  }

  constructor() {
    super()
    this._client = new Http({
      baseUrl: `${BASE_URL}`,
      //headers: {
      //  ['x-asis']: 'kleper',
      //},
    })
  }

  protected getBuketName(isSchool = false) {
    return isSchool ? SCHOOL_BUCKET_NAME : APP_BUCKET_NAME
  }

  async createFolder(folderName: string, isSchool?: boolean) {
    return this.send(
      this._client.post(FsEndpoint.FolderCreate, {
        folderName,
        bucketName: this.getBuketName(isSchool),
      }),
    )
  }

  async listFiles(
    params?: Params,
    filters?: {
      tags: FsTagReqBody
      or: boolean
    },
  ) {
    const paramStr = params ? `?${params.toString()}` : ''
    return await this.send<PaginatedItems<FsItem>>(
      this._client.post(`file/list${paramStr}`, filters),
    )
  }
  async listFilesOther(
    params?: Params,
    filters?: {
      tags: FsTagReqBody
      or: boolean
    },
  ) {
    const paramStr = params ? `?${params.toString()}` : ''
    return await this.send<PaginatedItems<FsItem>>(
      this._client.post(`file/list/other${paramStr}`, filters),
    )
  }
  async listImage(
    params?: Params,
    filters?: {
      tags: FsTagReqBody
      or: boolean
    },
  ) {
    const paramStr = params ? `?${params.toString()}` : ''
    return await this.send<PaginatedItems<FsItem>>(
      this._client.post(`file/list/image${paramStr}`, filters),
    )
  }

  async listVideo(
    params?: Params,
    filters?: {
      tags: FsTagReqBody
      or: boolean
    },
  ) {
    const paramStr = params ? `?${params.toString()}` : ''
    return await this.send<PaginatedItems<FsItem>>(
      this._client.post(`file/list/video${paramStr}`, filters),
    )
  }
  async listAudio(
    params?: Params,
    filters?: {
      tags: FsTagReqBody
      or: boolean
    },
  ) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<FsItem>>(
      this._client.post(`file/list/audio${paramStr}`, filters),
    )
  }
  async listGameExec(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Game>>(this._client.get(`games${paramStr}`))
  }
  async getGameExec(gameId: string) {
    return this.send<Game | undefined>(this._client.get(`games/${gameId}`))
  }

  async listGameAsset(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<GameAsset>>(
      this._client.get(`games/assets${paramStr}`),
    )
  }

  async addTags(key: string, tags: FsTagReqBody) {
    return await this.send<FsTag>(
      this._client.post(`file/${encodeURIComponent(key)}/tags`, tags),
    )
  }

  async updateCustomTags(key: string, tags: string[]) {
    return await this.send<void>(
      this._client.put(`file/${encodeURIComponent(key)}/tags/custom`, tags),
    )
  }
  /*async removeTag(key: string, tags: FsTagReqBody) {
    return await this.send<FsTag>(
      this._client.post(`file/${key}/tags/delete`, tags),
    )
  }*/

  async reTag(key: string) {
    return await this.send<FsTag>(
      this._client.put(`file/${encodeURIComponent(key)}/tags`),
    )
  }

  async reTagUpdateLesson(
    from: { key: string; tags: FsTagReqBody },
    to: {
      key: string
      tags: FsTagReqBody
    },
  ) {
    return await Promise.all([
      this.send<FsTag>(this._client.patch(`file/${to.key}/tags`, to.tags)),
      this.send<FsTag>(
        this._client.post(`file/${from.key}/tags/remove`, from.tags),
      ),
    ]).then(([res]) => {
      return res
    })
  }

  async getFile(filePath: string) {
    return this.send<FsItem>(this._client.get(`${filePath}`))
  }

  async addFile(filePath: string) {
    return this.send<FsItem>(this._client.get(`${filePath}`))
  }
  async deleteFile(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Fs}/${id}`))
  }

  uploadHeader(config?: AxiosRequestConfig) {
    const cfg = config || {}

    if (cfg.headers) {
      cfg.headers['Content-Type'] = 'multipart/form-data'
    } else {
      cfg.headers = {
        'Content-Type': 'multipart/form-data',
      }
    }

    return cfg as AxiosRequestConfig & {
      headers: Record<string, string>
    }
  }

  async uploadGame(
    value: GameReqBody,
    files: File | File[],
    config?: AxiosRequestConfig,
  ) {
    const formData = new FormData()
    formData.append('metadata', JSON.stringify(value))

    if (Array.isArray(files)) {
      for (const f of files) {
        formData.append('files', f)
      }
    } else {
      formData.append('files', files)
    }
    const cfg = this.uploadHeader(config)

    return this.send<FsItem[]>(
      this._client.post(`${FsEndpoint.FileUpload}/games`, formData, cfg),
    )
  }

  async updateGame(
    value: GameReqBody & {
      id: string
    },
  ) {
    return this.send<FsItem[]>(this._client.put(`games/${value.id}`, value))
  }

  async uploadGameAsset(files: File | File[], config?: AxiosRequestConfig) {
    const formData = new FormData()
    if (Array.isArray(files)) {
      for (const f of files) {
        formData.append('files', f)
      }
    } else {
      formData.append('files', files)
    }
    const cfg = this.uploadHeader(config)

    return this.send<GameAsset[]>(
      this._client.post(`${FsEndpoint.FileUpload}/games/assets`, formData, cfg),
    )
  }

  async upload(
    files: File | File[],
    options: FsQueryOption = DEFAULT_FS_QUERY_OPTION,
    config?: AxiosRequestConfig,
  ) {
    const formData = new FormData()
    if (Array.isArray(files)) {
      for (const f of files) {
        formData.append('files', f)
      }
    } else {
      formData.append('files', files)
    }

    const cfg = this.uploadHeader(config)

    if (options.folder) {
      cfg.headers['x-folder'] = options.folder
    }

    if (options.tags) {
      cfg.headers['x-tags'] = JSON.stringify(options.tags)
    }

    return this.send<FsItem[]>(
      this._client.post(FsEndpoint.FileUpload, formData, cfg),
    )
  }

  async migrate() {
    return this.send<void>(this._client.post('file/migrate'))
  }
}

export function getFsService() {
  return FsService.getInstance()
}

export function getFsUrl(
  path?: string | null,
  bucket = import.meta.env.VITE_APP_BUCKET_NAME,
) {
  if (path?.startsWith('http')) {
    return path
  }
  const withSlash = path?.startsWith('/') ?? true
  const withStream = path?.startsWith('/file/stream')
  if (path) {
    return `${BASE_URL}${!withStream ? `/file/stream/${bucket ? `${bucket}/` : ''}` : !withSlash ? '/' : ''}${encodeURIComponent(path)}`
  }
  return undefined
}

export function getFsName(path?: string) {
  const split = path?.split('/') ?? []

  if (path?.endsWith('/')) {
    return split[split.length - 2]
  }
  return split[split.length - 1]
}
