import { lazy } from 'react'

import {
  AdminChildrenPath,
  AdminCrudPath,
  AdminSeatFormChildrenPath,
} from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(
  () => import('@/pages/AdminPage/AdminSeatQuestionsPage/index.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () => import('@/pages/AdminPage/AdminSeatQuestionsPage/IndexPage.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const Question = lazy(
  () => import('@/pages/AdminPage/AdminSeatQuestionsPage/QuestionPage.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const Group = lazy(
  () => import('@/pages/AdminPage/AdminSeatQuestionsPage/GroupPage.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const AddGroup = lazy(
  () => import('@/pages/AdminPage/AdminSeatQuestionsPage/AddGroupPage.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const EditGroup = lazy(
  () => import('@/pages/AdminPage/AdminSeatQuestionsPage/EditGroupPage.tsx'),
)
export default {
  path: AdminChildrenPath.SEAT_FORM,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminSeatFormChildrenPath.QUESTION,
      element: <Question />,
    },
    {
      path: AdminSeatFormChildrenPath.GROUP,
      element: <Group />,
      children: [
        {
          path: AdminCrudPath.CREATE,
          element: <AddGroup />,
        },
        {
          path: `:groupId`,
          element: <EditGroup />,
        },
      ],
    },
  ],
}
