import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { AdminSchoolChildrenPath } from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () =>
    import('@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsFormPage/index.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(
  () => import('@/pages/AdminPage/AdminQuestionnaireFormPage/Edit.tsx'),
)

export default {
  path: `:id/${AdminSchoolChildrenPath.FORMS}`,
  element: (
    <Suspense>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: `:formId`,
      element: <Edit isSchool />,
    },
  ],
}
