import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { AdminChildrenPath, AdminCrudPath } from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const IndexPage = lazy(() => {
  return import('@/pages/AdminPage/AdminGamePage/index.tsx')
})

// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(() => {
  return import('@/pages/AdminPage/AdminGamePage/Create.tsx')
})

// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(() => {
  return import('@/pages/AdminPage/AdminGamePage/Edit.tsx')
})

export default {
  path: AdminChildrenPath.GAME,
  element: (
    <Suspense>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: <IndexPage />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:gameId`,
      element: <Edit />,
    },
  ],
}
