import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { AdminChildrenPath } from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const IndexPage = lazy(() => {
  return import('@/pages/AdminPage/AdminTutorialPage/index.tsx')
})

export default {
  path: AdminChildrenPath.TUTORIAL,
  element: (
    <Suspense>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: <IndexPage />,
    },
  ],
}
