import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import {
  AdminChildrenPath,
  AdminCrudPath,
  AdminQuestionnaireFormChildrenPath,
} from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const IndexPage = lazy(() => {
  return import('@/pages/AdminPage/AdminQuestionnaireFormPage/index.tsx')
})

// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(() => {
  return import('@/pages/AdminPage/AdminQuestionnaireFormPage/Create.tsx')
})

// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(() => {
  return import('@/pages/AdminPage/AdminQuestionnaireFormPage/Edit.tsx')
})

// eslint-disable-next-line react-refresh/only-export-components
const QuestionIndex = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminQuestionnaireFormPage/QuestionIndex.tsx'
  )
})
export default {
  path: AdminChildrenPath.QUESTIONNAIRE_FORM,
  element: (
    <Suspense>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: <IndexPage />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:formId`,
      element: <Edit />,
    },
    {
      path: AdminQuestionnaireFormChildrenPath.QUESTION,
      element: <QuestionIndex />,
    },
  ],
}
