import { Button, TabPanel, Textarea } from '@material-tailwind/react'
import { useEffect, useState } from 'react'

import { useFm } from '@/components/FileManager/hooks.ts'
import { FileManagerTab } from '@/components/FileManager/types.ts'
import { errorToast } from '@/helpers/toast.tsx'
import { EventService } from '@/services/event.ts'
/*
export function FileItem({
  file,
  onRemove,
}: {
  file: File
  onRemove: () => void
}) {
  return (
    <div
      title={file.name}
      className="text-body hover:border-blue-500 cursor-pointer flex-c-2 p-2 border border-white/20 rounded-md"
    >
      <div className="flex-c-2 text-white truncate w-full">
        <Sym className="!text-[20px]">description</Sym>
        <span className="truncate">{file.name}</span>
      </div>
      <button
        className="flex-shrink-0 hover:text-red-500"
        role="button"
        onClick={onRemove}
      >
        <Sym>close</Sym>
      </button>
    </div>
  )
}*/

export default function FileManagerAIPanel() {
  const { aiPrompt, onPromptChange, aiImage, setAiImage, aiImageName } = useFm()

  const [prompt, setPrompt] = useState(aiPrompt || '')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (aiPrompt) {
      setPrompt(aiPrompt)
    }
  }, [aiPrompt])

  return (
    <TabPanel className="py-4 px-0" value={FileManagerTab.AI}>
      <div className="flex flex-col gap-4 items-stretch">
        <div className="flex flex-col gap-4">
          <Textarea
            label="Prompt"
            value={prompt}
            onChange={(e) => {
              const val = e.currentTarget.value
              onPromptChange ? onPromptChange(val, e) : setPrompt(val)
            }}
          />
          <div className="flex justify-end">
            <Button
              loading={loading}
              onClick={async () => {
                setLoading(true)
                try {
                  const image = await EventService.getInstance().genImageBR(
                    prompt,
                    aiImageName || 'image.png',
                  )
                  setAiImage(image)
                } catch (e) {
                  errorToast(`${e}`)
                } finally {
                  setLoading(false)
                }
              }}
            >
              Generate
            </Button>
          </div>
        </div>
        {aiImage && (
          <div className="flex-c gap-4 max-h-[300px] justify-center overflow-hidden">
            <img
              className="border p-2 rounded-md"
              width={300}
              height={300}
              src={aiImage.url}
            />
          </div>
        )}
      </div>
    </TabPanel>
  )
}
