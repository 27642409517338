import { InputProps } from '@material-tailwind/react'
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react'

import InputWithHelp from '@/components/Form/InputWithHelp.tsx'

export type NumInputProps = Omit<InputProps, 'ref'> & {
  help?: string
  helpProps?: ComponentPropsWithoutRef<'div'>
  decimal?: boolean
}
const NumInput = forwardRef(function NumInput(
  props: NumInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <InputWithHelp
      ref={ref}
      type="number"
      {...props}
      onKeyDown={(e) => {
        const val = e.key
        if (val === '-' && e.currentTarget.value === '') {
          return true
        }
        if (
          props.decimal &&
          val === '.' &&
          !e.currentTarget.value.includes('.')
        ) {
          return true
        }
        if (
          val !== '' &&
          !/[0-9]+/.test(val) &&
          e.key !== 'Backspace' &&
          e.key !== 'Delete' &&
          e.key !== 'Tab' &&
          !e.ctrlKey
        ) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    />
  )
})

export default NumInput
