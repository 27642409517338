export function base64PngToImage(base64Data: string, fileName: string) {
  // Decode the Base64 string to a binary array
  const byteCharacters = atob(base64Data)
  const byteNumbers = new Array(byteCharacters.length)
    .fill(0)
    .map((_, i) => byteCharacters.charCodeAt(i))
  const byteArray = new Uint8Array(byteNumbers)

  // Create a File object
  return new File([byteArray], fileName, { type: 'image/png' })
}
