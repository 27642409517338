import { useTheme } from '@material-tailwind/react'
import objectsToString from '@material-tailwind/react/utils/objectsToString'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface DraggableDialogBodyProps
  extends ComponentPropsWithoutRef<'div'> {
  divider?: boolean
  className?: string
  children: ReactNode
}

export const DraggableDialogBody = forwardRef<
  HTMLDivElement,
  DraggableDialogBodyProps
>(function DialogBody({ divider, className, children, ...rest }, ref) {
  // 1. init
  const { dialogBody } = useTheme()
  const {
    defaultProps,
    styles: { base },
  } = dialogBody

  // 2. set default props
  className = twMerge(defaultProps.className || '', className)

  // 3. set styles
  const dialogBodyClasses = twMerge(
    clsx(objectsToString(base.initial), {
      [objectsToString(base.divider)]: divider,
    }),
    className,
  )

  // 4. return
  return (
    <div
      data-testid="draggable-dialog-body"
      {...rest}
      ref={ref}
      className={dialogBodyClasses}
    >
      {children}
    </div>
  )
})

export default DraggableDialogBody
