import { nanoid } from '@edclass/fe-ui'

export const DUMMY_GAMES: Game[] = [
  {
    title: 'Game 1',
    fields: [
      {
        id: '4EZOAJ',
        title: 'Field 0',
        name: 'field-0',
        body: '',
        type: 'text',
        target: 'admin',
        props: {
          required: false,
        },
        disabled: false,
      },
      {
        id: '05lGap',
        title: 'Grid Size',
        name: 'grid',
        body: '',
        type: 'numtuple',
        target: 'admin',
        props: {
          required: false,
          tupleLabel: ['Rows', 'Cols'],
        },
        disabled: false,
      },
      {
        id: 'com4ta',
        title: 'Size',
        name: 'size',
        body: '',
        type: 'mcq',
        target: 'admin',
        props: {
          required: false,
          options: [
            {
              label: 'TwoRows',
              id: '01JGDV26VCTC3P3XQPDTSRQC14',
            },
            {
              label: 'ThreeRows',
              id: '01JGDV2ADRC2TB6E14Z0FK91ZT',
            },
            {
              label: 'FourRows',
              id: '01JGDV2N2V24A7TQ1E9T5ME0XH',
            },
          ],
        },
        disabled: false,
      },
    ],
    body: 'Game description goes in here',
    tags: [],
    assets: [],
    file: '',
    objectName: '',
    methodName: '',
    createdAt: new Date(),
    id: nanoid(),
  },
  {
    title: 'Game 2',
    assets: [],
    fields: [
      {
        id: '4EZOAJ',
        title: 'Field 0',
        name: 'field-0',
        body: '',
        type: 'text',
        target: 'admin',
        props: {
          required: false,
        },
        disabled: false,
      },
      {
        id: '05lGap',
        title: 'Grid Size',
        name: 'grid',
        body: '',
        type: 'numtuple',
        target: 'admin',
        props: {
          required: false,
          tupleLabel: ['Rows', 'Cols'],
        },
        disabled: false,
      },
      {
        id: 'com4ta',
        title: 'Size',
        name: 'size',
        body: '',
        type: 'mcq',
        target: 'admin',
        props: {
          required: false,
          options: [
            {
              label: 'TwoRows',
              id: '01JGDV26VCTC3P3XQPDTSRQC14',
            },
            {
              label: 'ThreeRows',
              id: '01JGDV2ADRC2TB6E14Z0FK91ZT',
            },
            {
              label: 'FourRows',
              id: '01JGDV2N2V24A7TQ1E9T5ME0XH',
            },
          ],
        },
        disabled: false,
      },
    ],
    body: 'Game description goes in here',
    tags: [],
    file: '',
    objectName: '',
    methodName: '',
    createdAt: new Date(),
    id: nanoid(),
  },
]
