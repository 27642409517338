import { type To } from 'react-router-dom'

import { AdminCrudPath, BASE_PATH, FolderName } from '@/constants/constants.ts'

export enum PathKind {
  Admin = 'admin',
  Safeguarding = 'safeguarding',
  Teacher = 'teachers',
  Staff = 'staffs',
  Student = 'students',
  Parent = 'parents',
  SchoolStaff = 'school-staffs',
}

export function mergePath(...args: string[]) {
  return args
    .filter((a, i) => {
      if (i === 0) return true
      return a !== ''
    })
    .join('/')
}

export function getPath(
  kind: PathKind,
  path?: string | To,
  options?: {
    crud?: keyof typeof AdminCrudPath
    params?: string
  },
): string {
  const { crud, params } = options || {}
  switch (kind) {
    case PathKind.Admin:
      return getAdminPath(
        path,
        `${getCrudPath(crud)}${params ? `/${params}` : ''}`,
      )
    case PathKind.Teacher:
      return getTeacherPath(
        path,
        `${getCrudPath(crud)}${params ? `/${params}` : ''}`,
      )
    case PathKind.Student:
      return getStudentPath(path, params)
    case PathKind.Parent:
      return getParentPath()
    case PathKind.Staff:
      return ''
    case PathKind.Safeguarding:
      return getSafeguardingPath(path, params)
    case PathKind.SchoolStaff:
      return getSchoolAdminPath()
  }
}

function getCrudPath(crud?: keyof typeof AdminCrudPath) {
  return crud ? AdminCrudPath[crud] || '' : ''
}

function getAdminPath(path?: string | To, params?: string) {
  return `${BASE_PATH ? BASE_PATH : ''}/${PathKind.Admin}${path ? `/${path}` : ''}${params ? `/${params}` : ''}`
}

function getTeacherPath(path?: string | To, params?: string) {
  return `${BASE_PATH ? BASE_PATH : ''}/${PathKind.Teacher}${path ? `/${path}` : ''}${params ? `/${params}` : ''}`
}

function getStudentPath(path?: string | To, params?: string) {
  const paths: string[] = [BASE_PATH, PathKind.Student, path as string]
  return `${mergePath(...paths)}${params || ''}`
}

function getSafeguardingPath(path?: string | To, params?: string) {
  const paths: string[] = [BASE_PATH, PathKind.Safeguarding, path as string]
  return `${mergePath(...paths)}${params || ''}`
}

function getParentPath() {
  return ''
}

function getSchoolAdminPath() {
  return ''
}

export function getQuestionDir(dir?: string) {
  return `${FolderName.Lesson}/${FolderName.Question}/${dir ? `${dir}/` : ''}`
}
export function getDndItemDir(dir?: string) {
  return `${FolderName.Lesson}/${FolderName.Dnd}/${dir ? `${dir}/` : ''}`
}
export function getGameDir(dir?: string) {
  return `${FolderName.Game}/${dir ? `${dir}/` : ''}`
}
export function getReviewDir(edcid: number, revDir: string) {
  return `${getRevisionDir(edcid, revDir)}reviews/`
}

export function getRevisionDir(edcid: number, revDir: string) {
  return `${FolderName.Lesson}/${edcid}/${revDir}/`
}
