import { useQuery } from '@tanstack/react-query'

import { QueryKeys } from '@/constants/query.ts'
import { getApiService } from '@/services/api.ts'

export default function useTutorial(path: string) {
  return useQuery({
    queryKey: [QueryKeys.Tutorial, path],
    queryFn: async ({ queryKey: [, path] }) => {
      return getApiService().getTutorial(path)
    },
  })
}
