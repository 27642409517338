import { useLocation } from 'react-router-dom'

export function usePathname() {
  const location = useLocation()
  const split = location.pathname.split('/')
  return split[split.length - 1]
}

export function usePathNames() {
  const { pathname } = useLocation()
  return pathname.split('/').slice(1)
}
