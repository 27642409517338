import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { DebouncedState, useDebounce } from 'use-debounce'

export type SearchContextValue = {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  debouncedSearch: string
  debounced: DebouncedState<(value: string) => void>
}

export const SearchContext = createContext<SearchContextValue | null>(null)
SearchContext.displayName = 'SearchContext'

export default function SearchProvider({ children }: { children: ReactNode }) {
  const [searchParams] = useSearchParams()
  const [search, setSearch] = useState<string>(searchParams.get('search') || '')
  const [debouncedSearch, debounced] = useDebounce(search, 800)

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch,
        debouncedSearch,
        debounced,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
