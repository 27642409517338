export enum StatsRequestType {
  ALERT = 'alert',
  USER_AUDIT = 'user-audit',
  LOGIN = 'login',
  FAILED_LOGIN = 'failed-login',
  NAVIGATION = 'navigation',
}

export enum QueryKeys {
  EDTeachRoom = 'edteach-room',
  LoginCheck = 'login-check',
  Skill = 'skill',
  LsSkill = 'skills',
  LsSkillInf = 'skills-infinite',
  LsSkillArchive = 'skills-archive',
  SkillTag = 'skill-tag',
  LsSkillTag = 'skill-tags',
  LsSkillTagTree = 'skill-tags-tree',
  LsSkillTagInf = 'skill-tags-infinite',
  LsSkillTagArchive = 'skill-tags-archive',
  SubjectSkill = 'subject-skill',
  LsSubjectSkill = 'subject-skills',
  LsSubjectSkillInf = 'subject-skills-infinite',
  LsSubjectSkillArchive = 'subject-skills-archive',
  KeySkill = 'key-skill',
  LsKeySkill = 'key-skills',
  LsKeySkillTree = 'key-skills-tree',
  LsKeySkillInf = 'key-skills-infinite',
  LsKeySkillArchive = 'key-skills-archive',
  Lesson = 'lesson',
  LessonActive = 'lesson-active',
  LsLesson = 'lessons',
  LsLessonInf = 'lessons-infinite',
  LsLessonArchive = 'lessons-archive',
  LsLessonByTopic = 'lessons-by-topic',
  LsLessonRevision = 'element-revisions',
  LessonRevision = 'element-revision',
  LsLessonSlide = 'element-slides',
  LsReviewComment = 'review-comments',
  LsQuestion = 'questions',
  LsQuestionInf = 'questions-infinite',
  LsQuestionArchive = 'questions-archive',
  Question = 'question',
  QuestionBulk = 'question-bulk',
  Game = 'game',
  LsGame = 'games',
  LsGameInf = 'games-inf',
  LsGameItem = 'game-items',
  GameAsset = 'game-asset',
  LsGameAsset = 'game-assets',
  LsGameAssetInf = 'game-assets-inf',
  GameExec = 'game-exec',
  LsGameExec = 'game-execs',
  LsGameExecInf = 'game-execs-inf',
  LsDnDItem = 'dnd-items',
  LsDndItemInf = 'dnd-items-infinite',
  LsDnDItemArchive = 'dnd-items-archive',
  DndItem = 'dnd-item',
  LsDAQ = 'daqs',
  LsDAQInf = 'daqs-infinite',
  LsDAQArchive = 'daqs-archive',
  DAQ = 'daq',
  LsDoodle = 'doodles',
  LsDoodleInf = 'doodles-infinite',
  LsDoodleArchive = 'doodles-archives',
  Doodle = 'doodle',
  LsPuzzle = 'puzzles',
  LsPuzzleInf = 'puzzles-infinite',
  LsPuzzleArchive = 'puzzles-archives',
  Puzzle = 'puzzle',
  LsStaff = 'staffs',
  LsStaffInf = 'staffs-infinite',
  Staff = 'staff',
  Teacher = 'teacher',
  LsTeacher = 'teachers',
  LsTeacherInf = 'teachers-infinite',
  LsStudent = 'students',
  Student = 'student',
  LsSeatCandidate = 'seat-candidates',
  LsSeatCandidateInf = 'seat-candidates-infinite',
  SeatCandidate = 'seat-candidate',
  LsSeatNonCandidate = 'seat-non-candidates',
  SeatNonCandidate = 'seat-non-candidate',
  LsBranch = 'branches',
  LsBranchInf = 'branches-infinite',
  Branch = 'branch',
  LsSchool = 'schools',
  LsSchoolInf = 'schools-infinite',
  School = 'school',
  LsSchoolForm = 'school-forms',
  LsSchoolFormInf = 'school-forms-infinite',
  SchoolForm = 'school-form',
  LsMatSchool = 'mat-schools',
  LsMatSchoolInf = 'mat-schools-infinite',
  MatSchool = 'mat-school',
  LsAcademicTerm = 'academic-terms',
  AcademicTerm = 'academic-term',
  LsSubject = 'subjects',
  LsSubjectArchive = 'subjects-archive',
  LsSubjectInf = 'subjects-infinite',
  LsSubjectTree = 'subjects-tree',
  LsLegacySubject = 'legacy-subjects',
  Subject = 'subject',
  LsTopic = 'topics',
  LsTopicArchive = 'topics-archive',
  LsTopicTree = 'topics-tree',
  LsTopicInf = 'topics-infinite',
  LsTopicAncestor = 'topic-ancestors',
  Topic = 'topic',
  LsPathway = 'pathways',
  LsPathwayArchive = 'pathways-archive',
  LsPathwayInf = 'pathways-infinite',
  LsPathwayRevision = 'pathway-revisions',
  LsStudentPathway = 'student-pathways',
  StudentPathway = 'student-pathway',
  Pathway = 'pathway',
  PathwayDraft = 'pathway-draft',
  LsSeat = 'seats',
  Seat = 'seat',
  SchoolSeat = 'school-seat',
  LsSeatQuiz = 'seat-questions',
  LsSeatQuizInf = 'seat-questions-inf',
  LsSeatQuizGroup = 'seat-questions-groups',
  LsSeatQuizGroupInf = 'seat-questions-groups-inf',
  SeatQuizGroup = 'seat-questions-group',
  SeatQuiz = 'seat-question',
  LsStudentQuiz = 'student-questions',
  StudentQuiz = 'student-question',
  LsTimetable = 'timetables',
  Timetable = 'timetable',
  LsStorage = 'storage',
  StorageMetric = 'storage-metric',
  LsUser = 'users',
  LsSubscription = 'subscriptions',
  Subscription = 'subscription',
  LsExBoard = 'ex-boards',
  LsExBoardArchive = 'ex-boards-archive',
  LsExBoardInf = 'ex-boards-infinite',
  ExBoard = 'ex-board',
  LsFile = 'files',
  LsImage = 'images',
  LsAudio = 'audios',
  LsVideo = 'videos',
  LsFileOther = 'file-others',
  LsTutorial = 'tutorials',
  Tutorial = 'tutorial',
  LsQForm = 'qforms',
  LsQFormInf = 'qforms-inf',
  QForm = 'qform',
  LsQSection = 'qsections',
  LsQSectionInf = 'qsections-inf',
  QSection = 'qsection',
  LsQQuestion = 'qquestions',
  LsQQuestionInf = 'qquestions-inf',
  QQuestion = 'qquestion',
}

export const QUERY_ITEMS_PER_PAGE = 12
