import { Sym } from '@edclass/fe-ui'
import { useTheme } from '@material-tailwind/react'
import objectsToString from '@material-tailwind/react/utils/objectsToString'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface DraggableDialogHeaderProps
  extends ComponentPropsWithoutRef<'div'> {
  className?: string
  children: ReactNode
  backArrow?: boolean
  withClose?: boolean
  handler?: SetState<unknown>
}

export const DraggableDialogHeader = forwardRef<
  HTMLDivElement,
  DraggableDialogHeaderProps
>(function DraggableDialogHeader(
  { className, handler, children, withClose, backArrow, ...rest },
  ref,
) {
  // 1. init
  const { dialogHeader } = useTheme()
  const {
    defaultProps,
    styles: { base },
  } = dialogHeader

  // 2. set default props
  className = twMerge(defaultProps.className || '', className)

  const dialogHeaderClasses = twMerge(clsx(objectsToString(base)), className)

  // 4. return
  return (
    <div
      data-testid="draggable-dialog-header"
      {...rest}
      ref={ref}
      className={dialogHeaderClasses}
    >
      {withClose ? (
        <div className={clsx('flex-c gap-4 w-full')}>
          <div className="flex-c gap-4 w-full">
            {backArrow && (
              <button
                className="flex items-center justify-center text-[20px] text-white"
                onClick={handler}
              >
                <Sym>arrow_back</Sym>
              </button>
            )}
            <div>{children}</div>
          </div>
          <button
            onClick={handler}
            className="flex-shrink-0 text-white/50 hover:text-white grid items-center"
          >
            <Sym>close</Sym>
          </button>
        </div>
      ) : (
        children
      )}
    </div>
  )
})
export default DraggableDialogHeader
